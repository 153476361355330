import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Signe du Temps ou Indice" />
    <h3 className='underline-title'>Signe du Temps ou Indice</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Un Chien hargneux clabaudant<br />
        <span className="par-starting"></span>- c'est une frousse<br />
        <span className="par-starting"></span>- c'est une caravane passante<br />
      </p>
      <p>
        Un diable furieux invectivant<br />
        <span className="par-starting"></span>- un bénétier dans les parages<br />
        <span className="par-starting"></span>- les approches d'Émmanuel<br />
      </p>
      <p>
        On doit alors entendre que<br />
        L'épée de Damoclès est dans son fourreau<br />
        <span className="par-starting"></span>liè à un baudrier<br />
      </p>
      <p>
        Donc<br />
        Les deux oreilles sont désormais des polochons<br />
      </p>
      <p>
        En revanche<br />
        <span className="par-starting"></span>un cocker sournois<br />
        <span className="par-starting"></span>un apôtre tartufe<br />
        Il est donc temps de songer à la circonspection<br />
      </p>
      <p>
        Comprenne qui pourra!<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
